import { getPaginated } from '@/api/implementation/app/wrapper.js';
import { get, post } from '@/api/implementation/app';

export const index = (...args) => getPaginated(`operations-sales-orders`, ...args);

export const secondIndex = (...args) => getPaginated(`report/sales-orders`, ...args);

export const show = (objectId) => get(`sales-order/${objectId}`);

export const update = (object) => post(`sales-order/${object.id}`, object);

export const typeAutoComplete = (...args) => getPaginated(`sales-order-issue-type/autocomplete`, ...args);

export const statusAutoComplete = (...args) => getPaginated(`sales-order-status/autocomplete`, ...args);
