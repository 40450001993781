<template>
  <v-container fluid>
    <h1 class="text-h1 mb-3">{{ title }}</h1>
    <resource
        ref="resource"
        :can-search="true"
        :can-delete="false"
        :use-checkboxes="false"
        :can-add="false"
        :can-update="false"
        :show-speed-dial="false"
        :index-request="index"
        :show-request="show"
        :update-request="mapAndUpdate"
        :form-component="() => import('@/components/forms/SalesOrderForm.vue')"
        :meta="{name: $tc('salesOrder.title', 1), namePlural: $tc('salesOrder.title', 2)}"
        :table-content="tableContent"
        :params="params"
        @row-click="openSalesOrder($event)"
    >
      <template #afterSearchBar>
        <v-row class="justify-end ml-1">
          <v-col cols="4">
            <SalesOrderStatusAutocomplete  :field="$t('salesOrder.filter.statusName')" @change="setQueryParams" solo v-model="params.salesOrderStatusIds"  multiple chips :return-object="false"/>
          </v-col>
          <v-col  cols="4">
            <UserAutocomplete :field="$t('salesOrder.filter.assignedUser')" @change="setQueryParams" solo v-model="params.assignedUserIds" multiple chips :return-object="false"/>
          </v-col>
          <v-col  cols="4">
            <IssueTypeAutocomplete :field="$t('salesOrder.filter.salesOrderIssue')" @change="setQueryParams" solo v-model="params.issueTypeIds" multiple chips :return-object="false" />
          </v-col>
        </v-row>
      </template>
      <template #crudActionsAfter="{resource}">
        <v-tooltip left>
          <template #activator="{on, attrs}">
            <v-btn text
                   :color="!resource.hasMetaData ? 'grey' : ''"
                   v-bind="attrs"
                   v-on="on"
                   @click.stop="handleEditClick(resource)">A
            </v-btn>
          </template>
          {{
            resource.hasMetaData ? $t("salesOrder.editTooltipWithMetaData") : $t("salesOrder.editTooltipWithoutMetaData")
          }}
        </v-tooltip>
      </template>
    </resource>
  </v-container>
</template>

<script lang="js">
import Resource from "@/components/vuetifyResource/Resource.vue";
import { secondIndex, show, update } from "@/api/endpoints/salesOrder.js";
import i18n from "@/plugins/i18n.js";
import Qs from "qs";
import { snakeToCamel } from "@/api/util/keyConverter.js";
import SalesOrderStatusAutocomplete from "@/components/autocompletes/SalesOrderStatusAutocomplete.vue";
import UserAutocomplete from '@/components/autocompletes/UserAutocomplete.vue';
import IssueTypeAutocomplete from '@/components/autocompletes/IssueTypeAutocomplete.vue';

export default {
  name: "FilteredSalesOrderTable", // @TODO waiting for a better name chosen by hexspoor
  components: {
    IssueTypeAutocomplete,
    UserAutocomplete,
    SalesOrderStatusAutocomplete,
    Resource,
  },
  data: () => ({
    title: i18n.tc("salesOrder.title", 2),
    params: {},
  }),
  computed: {
    tableContent() {
      return [
        {
          text: this.$t("salesOrder.table.orderNumber"),
          value: "orderNumber",
        },
        {
          text: this.$t("salesOrder.table.reference"),
          value: "reference",
        },
        {
          text: this.$t("salesOrder.table.clientName"),
          value: "clientName",
          sortable: false,
        },
        {
          text: this.$t("salesOrder.table.companyName"),
          value: "companyName",
        },
        {
          text: this.$t("salesOrder.table.fullName"),
          value: "fullName",
        },
        {
          text: this.$t("salesOrder.table.orderDate"),
          value: "orderDate",
        },
        {
          text: this.$t("salesOrder.table.deliveryDate"),
          value: "deliveryDate",
        },
        {
          text: this.$t("salesOrder.table.statusName"),
          value: "statusName",
        },
        {
          text: this.$t("salesOrder.table.assignedUserName"),
          value: "assignedUserName",
          sortable: false,
        },
        {
          text: this.$t("salesOrder.table.issueType"),
          value: "issueType",
          sortable: false,
        },
        {
          text: this.$t("salesOrder.table.lastCommentDate"),
          value: "lastCommentDate",
          sortable: false,
        },
      ];
    },
  },
  created() {
    const params = this.getQueryParams();
    if (params.salesOrderStatusIds) {
      params.salesOrderStatusIds = params.salesOrderStatusIds.map((id) => Number(id));
    }
    if (params.assignedUserIds) {
      params.assignedUserIds = params.assignedUserIds.map((id) => Number(id));
    }
    if (params.issueTypeIds) {
      params.issueTypeIds = params.issueTypeIds.map((id) => Number(id));
    }
    this.params = params;
  },
  methods: {
    getQueryParams() {
      return snakeToCamel(Qs.parse(this.$route.query, { allowSparse: false }));
    },
    setQueryParams() {
      this.$router.push({
        query: {
          'client_id': this.params.clientId,
          'filter': this.params.filter,
          'sales_order_status_ids[]' : this.params.salesOrderStatusIds,
          'assigned_user_ids[]' : this.params.assignedUserIds,
          'issue_type_ids[]' : this.params.issueTypeIds,
        },
      });
    },
    openSalesOrder(id) {
      if (window.getSelection && window.getSelection().type === "Range") return;
      this.$router.push({
        name: "report.show",
        params: { reportType: "sales-order-lines" },
        query: { sales_order_id: id },
      });
    },
    async index(...args) {
      const response = await secondIndex(...args);
      this.title = response.data.title;

      return response;
    },
    show,
    mapAndUpdate(resource) {
      return update({
        id: resource.id,
        assignedUserId: resource.assignedUser?.id,
        salesOrderIssueId: resource.salesOrderIssue?.id,
        statusId:resource.status?.id,
        comment: resource.comment,
      });
    },
    handleEditClick(item) {
      this.$refs.resource.$refs.resourceList.openUpdateHandler(item.salesOrderId);
    },
  },
};
</script>
